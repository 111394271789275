import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import * as Leaflet from 'leaflet';
import 'leaflet-rotatedmarker';
import { InfiniteScrollCustomEvent, LoadingController, ModalController, NavController } from '@ionic/angular';
import { ViewAlertPage } from 'src/app/view-alert/view-alert.page';

@Component({
  selector: 'app-reportes-registros',
  templateUrl: './reportes-registros.page.html',
  styleUrls: ['./reportes-registros.page.scss'],
})
export class ReportesRegistrosPage implements OnInit {

  imei:any = null;
  inicial:any =  null;
  final:any =  null;
  objeto:any = {};
  type:any = "mapa";
  map: Leaflet.Map;
  lat: number = 21.9934775;
  lng: number = -99.0176878;
  tabselect:boolean = false;
  href:any;
  url:any = "";
  speedPromedio:any = {};  
  registros:any = [];
  loadInicial:number = 0;
  loadFinal:number = 30;
  registros_add:any =[];

  constructor( private _api:ApiService, public route: ActivatedRoute, public loadingCtrl:LoadingController,
              public router:Router, public navCtrl: NavController, public modalCtrl:ModalController ) { 
    // this.route.params.subscribe((params:any)=>{
    //   console.log("params",params);
    //   this.imei    = params.id
    //   this.inicial = params.inicial;
    //   this.final = params.final;
    //   this.getObjeto();
      
    // }); 
  }

  ngOnInit() {
    // this.imei    = localStorage.getItem("imei");
    // this.inicial = localStorage.getItem("inicial");
    // this.final   = localStorage.getItem("final");
    // this.getObjeto();
    // this.historialObjeto();
  }

  ionViewWillEnter(){
    console.log(this.tabselect);
    if(!this.tabselect){
      this.tabselect = true;
    }

    this.imei    = localStorage.getItem("imei");
    this.inicial = localStorage.getItem("inicial");
    this.final   = localStorage.getItem("final");
    this.getObjeto();
    this.historialObjeto();
  }

  async historialObjeto(){

    const loading = await this.loadingCtrl.create({
      message:"Cargando reporte, espere un momento.",
      spinner:"circles"
    });

    loading.present();

    this._api.historialObjeto({ finicial:this.inicial, ffinal:this.final, imei:this.imei }).subscribe((data:any)=>{
      loading.dismiss();
      
      this.speedPromedio = this.velocidadPromedio( data.data );      
      this.registros = data.data;
      console.log(this.registros);
      
      this.generateItems();

    },(err:any)=>{
      loading.dismiss();
    });
  }

  velocidadPromedio( data:any ){
    let suma =  0;
    let speeds = [];
    let num  = 0;
    let num2 = 1;
    let distancia = 0;

    for( let speed in data ){
      suma = suma + data[speed].speed;
      speeds.push( data[speed].speed );
      if(data[num] && data[num2]){
        let dist = this.getDistanciaLatLngEnKm( data[num].lat, data[num].lng, data[num2].lat, data[num2].lng );
        distancia = distancia + dist;       
      }
      num++;
      num2++;
    }

    let promedio = suma / data.length;

    return { promedio: promedio.toFixed(1), max_speed: Math.max(...speeds), distancia: distancia.toFixed(1) }    

  }  

  getObjeto(){
     this._api.getObjeto( {imei:this.imei} ).subscribe((data:any)=>{
      console.log( data );
      this.objeto = data.data[0];
    });
  }

  getDistanciaLatLngEnKm( lat1, lon1, lat2, lon2 ){

    let R = 6378.137;
    let dLat = this.deg2rad( lat2 - lat1 );
    let dLon = this.deg2rad( lon2 - lon1 );

    let a =  Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon/2) * Math.sin(dLon/2);
    let c = 2 * Math.atan2( Math.sqrt(a), Math.sqrt(1-a) );
    let d = R * c;
    return d;
  }

  deg2rad(n){
    return n * (Math.PI/180);
  }

  private generateItems() {    
    for ( let reg in this.registros ) {
      // console.log(this.loadInicial +">="+ parseInt( reg ) +"&&"+ this.loadFinal +"<="+ parseInt( reg ));
      if(this.loadInicial <= parseInt( reg ) && this.loadFinal >= parseInt( reg )){        
        this.registros_add.push( this.registros[reg] );
      }
    }
  }

  onIonInfinite(ev:any){
    console.log(ev);
    
    setTimeout(() => {
      this.generateItems();
      this.loadInicial = this.loadInicial + 30;
      this.loadFinal   = this.loadFinal + 30;
      (ev as InfiniteScrollCustomEvent).target.complete();
    }, 500);
  }

  back(){
    this.navCtrl.back();
  }

  async openUbicacion(data:any){
    console.log(data);
    data.tipo = 2;
    data.mensaje = data.acc == 0 ? "Motor apagado": "Motor encendido"; 

    const modal = await this.modalCtrl.create({
      component:ViewAlertPage,
      componentProps:{
        data: data
      }
    });

    modal.present();

  }

}
